import type { CSSProperties } from 'preact/compat'

export const Logo = ({
	class: className,
	style,
}: {
	class?: string
	style?: CSSProperties
}) => (
	<svg
		width="36.242619mm"
		height="26.304871mm"
		viewBox="0 0 36.242619 26.304871"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		class={className ?? ''}
		title={'hello.nrfcloud.com Logo'}
		style={style}
	>
		<g transform="translate(-136.525,-123.825)">
			<path
				d="m 158.22083,142.28762 h -4.96358 c -0.28046,0 -0.51065,-0.22754 -0.51065,-0.51064 0,-0.28311 0.22755,-0.51065 0.51065,-0.51065 h 4.96358 c 0.28046,0 0.51065,0.22754 0.51065,0.51065 0,0.2831 -0.22754,0.51064 -0.51065,0.51064 z"
				style="fill:currentColor;stroke-width:0.264583;fill-opacity:1"
			/>
			<path
				d="m 158.22083,144.38312 h -4.96358 c -0.28046,0 -0.51065,-0.22754 -0.51065,-0.51064 0,-0.28311 0.22755,-0.51065 0.51065,-0.51065 h 4.96358 c 0.28046,0 0.51065,0.22754 0.51065,0.51065 0,0.2831 -0.22754,0.51064 -0.51065,0.51064 z"
				style="fill:currentColor;stroke-width:0.264583;fill-opacity:1"
			/>
			<path
				d="m 161.74244,150.12987 c -0.19579,0 -0.38894,-0.0714 -0.53975,-0.21166 l -2.10079,-1.94469 h -7.07761 c -1.95527,0 -3.54542,-1.59015 -3.54542,-3.54277 v -3.28877 c 0,-1.95527 1.59015,-3.54542 3.54542,-3.54542 h 6.96648 c 1.95527,0 3.54542,1.59015 3.54542,3.54542 v 8.19414 c 0,0.31486 -0.18786,0.60061 -0.47625,0.72761 -0.10319,0.045 -0.21167,0.0661 -0.3175,0.0661 z m -2.32834,-3.74385 c 0.20109,0 0.39159,0.0741 0.53975,0.21167 l 0.99484,0.92075 v -6.37646 c 0,-1.0795 -0.87842,-1.95792 -1.95792,-1.95792 h -6.96648 c -1.0795,0 -1.95792,0.87842 -1.95792,1.95792 v 3.28877 c 0,1.0795 0.87842,1.95792 1.95792,1.95792 h 7.38981 z"
				style="fill:currentColor;stroke-width:0.264583;fill-opacity:1"
			/>
			<path
				d="m 166.61606,132.33665 c -0.4736,-2.2569 -1.66952,-4.31007 -3.41841,-5.8473 -1.95528,-1.71714 -4.46617,-2.66435 -7.07232,-2.66435 -2.60614,0 -5.19906,0.97631 -7.17021,2.75167 -1.67216,1.50548 -2.82045,3.48456 -3.29935,5.65414 -0.39952,-0.0608 -0.80433,-0.0926 -1.21179,-0.0926 -4.36563,0 -7.91898,3.55335 -7.91898,7.92162 0,2.10609 0.94192,4.10898 2.65377,5.64092 1.61925,1.44992 3.75444,2.28071 5.85258,2.28071 h 1.651 c 0.56092,0 1.01336,-0.45244 1.01336,-1.01336 0,-0.56091 -0.45244,-1.01335 -1.01336,-1.01335 h -1.651 c -3.39195,0 -6.47964,-2.80988 -6.47964,-5.89492 0,-3.25173 2.64583,-5.89491 5.89491,-5.89491 0.59267,0 1.17475,0.0873 1.73567,0.25929 0.2884,0.09 0.60061,0.045 0.85461,-0.12171 0.25135,-0.16669 0.41804,-0.43392 0.45243,-0.73554 0.49213,-4.40002 4.20423,-7.71525 8.63865,-7.71525 4.43442,0 8.02217,3.22262 8.60954,7.49564 0.0635,0.45244 0.42069,0.80698 0.87313,0.86784 2.92364,0.37835 5.13027,2.89189 5.13027,5.84464 0,3.25173 -2.64584,5.89492 -5.89492,5.89492 -0.56092,0 -1.01335,0.45244 -1.01335,1.01335 0,0.56092 0.45243,1.01336 1.01335,1.01336 4.36827,0 7.92162,-3.55336 7.92162,-7.92163 0,-3.71475 -2.59556,-6.90827 -6.14891,-7.72318 z"
				style="fill:currentColor;stroke-width:0.264583;fill-opacity:1"
			/>
		</g>
	</svg>
)
